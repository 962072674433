/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import * as React from 'react';
import { graphql } from 'gatsby';

// Lib UI components

// Local UI components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import BannerSection from '../page-components/SupportOffer/BannerSection/index';
import Form from '../page-components/SupportOffer/Form';
// Style
import '../page-styles/SupportOffer.scss';
import Description from '../page-components/SupportOffer/Description';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function SupportOffer() {
  /* ********************************** HOOKS ********************************* */

  // Localization

  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title="Support offer" />
      <div className="support-offer">
        <BannerSection />
        <Description />
        <Form />
      </div>
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["SupportOffer", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default SupportOffer;
